import type { T_LangValue } from "@/lib/models/types";

export default {
  A_SET_INTERFACE_LANG(
    context: { commit: (arg0: string, arg1: T_LangValue) => void },
    payload: T_LangValue
  ): void {
    context.commit("M_SET_INTERFACE_LANG", payload);
  },
};
