import { createStore } from "vuex";

import main from "./modules/main/store";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    main,
  },
});
