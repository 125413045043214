import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./css/bootstrap.css";
import "./css/style.css";
import "./css/responsive.css";
import "./css/color-switcher-design.css";
import "./css/color-themes/default-theme.css";

// import "./js/local.js";

createApp(App).use(store).use(router).mount("#app");
